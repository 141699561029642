const footerMenuList = [
	{
		id: 1,
		title: "Links",
		diffClass: "md:col-start-7",
		list: [
			{
				id: 1,
				href: "/",
				text: "Home",
			},
			{
				id: 2,
				href: "/",
				text: "Web3 Jobs",
			},
			{
				id: 3,
				href: "/about",
				text: "About",
			},
		],
	},
	{
		id: 2,
		title: "More",
		diffClass: "",
		list: [
			{
				id: 1,
				href: "/faq",
				text: "FAQ",
			},
		],
	},
	{
		id: 3,
		title: "My Account",
		diffClass: "",
		list: [
			{
				id: 1,
				href: "/post-job",
				text: "Post a Job",
			},
			{
				id: 2,
				href: "/contact",
				text: "Contact us",
			},
		],
	},
];

const socialIcons = [
	{
		id: 1,
		href: "https://www.facebook.com",
		text: "facebook",
	},
	{
		id: 2,
		href: "https://www.twitter.com",
		text: "twitter",
	},
	{
		id: 3,
		href: "https://www.discord.com",
		text: "discord",
	},
	{
		id: 4,
		href: "https://www.instagram.com",
		text: "instagram",
	},
	{
		id: 5,
		href: "https://www.tiktok.com",
		text: "tiktok",
	},
];

export { footerMenuList, socialIcons };
