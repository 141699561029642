import Head from "next/head";

type MetaProps = {
  title?: string;
  keyword?: string;
  desc?: string;
};

const Meta: React.FC<MetaProps> = ({ title, keyword, desc }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='title' content={title} />
        <link rel='icon' href='/favicon.png' />
        <meta name='description' content={desc} />
        <meta name='keyword' content={keyword} />
      </Head>
    </>
  );
};

Meta.defaultProps = {
  title: "Web3 Jobs by DXHR",
  keyword:
    "bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet",
  desc: "The world's first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.",
};

export default Meta;
