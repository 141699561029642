import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useState, useEffect, useRef } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

// NProgress
import Router from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// Main block
function MyApp({ Component, pageProps }) {
	const router = useRouter();
	const pid = router.asPath;
	const scrollRef = useRef({
		scrollPos: 0,
	});
	const [queryClient] = useState(() => new QueryClient());

	useEffect(() => {
		// if (pid === '/home/home_8') {
		// 	const html = document.querySelector('html');
		// 	html.classList.remove('light');
		// 	html.classList.add('dark');
		// }
	}, []);

	return (
		<>
			<Meta title="Home 1 || Web3 Jobs by DXHR" />

			{/* */}
			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<ThemeProvider enableSystem={true} attribute="class">
						<MetaMaskProvider>
							<UserContext.Provider
								value={{ scrollRef: scrollRef }}
							>
								{pid === "/login" ? (
									<Component {...pageProps} />
								) : (
									<Layout>
										<Component {...pageProps} />
									</Layout>
								)}
							</UserContext.Provider>
						</MetaMaskProvider>
					</ThemeProvider>
				</Provider>
			</QueryClientProvider>
		</>
	);
}

export default MyApp;
