// ReactQuery
import { useQuery } from "react-query";
import { Announcement, AnnouncementsResponse } from "../../types/strapi/announcement";
// Fetch for Strapi
import { fetchAPI } from "../strapi/fetch-api";
import { upsertAPI } from "../strapi/upsert-api";

/**
 * Create
 */

/**
 * Read
 */

// List
export function useAnnouncementList(options = {}, config = {}) {
  const { data, isLoading, error } = useQuery(
    ["key-announcement-list", options],
    queryAnnouncementList
  );
  return {
    announcement: data?.data ,
    isLoading,
    error,
  };
}

// Query for List
const queryAnnouncementList = async () => {
  const response = await fetchAPI("announcements", {
    _sort: "id:ASC",
  });

  console.log("queryAnnouncementList", response);
  return {
    data: response,
  };
};

// Single

// Query for Single

/**
 * Update
 */

/**
 * Delete
 */
