import qs from "qs";

// Main block
export async function fetchAPI(endpoint, urlParamsObject = {}, options = {}) {
	// Fetch Options with Strapi Token
	const mergedFetchOptions = {
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + process.env.NEXT_PUBLIC_STRAPI_API_TOKEN,
		},
		...options,
	};

	// Query Builder for Get
	const queryString = qs.stringify(urlParamsObject);

	// Request
	const request = `${getStrapiURI(
		`/api/${endpoint}${queryString ? `?${queryString}` : ""}`
	)}`;

	// API call
	try {
		const response = await fetch(request, mergedFetchOptions);
		return await response.json();
	} catch (err) {
		console.error(err.message);
		throw new Error(`An error occured. please try again`);
	}
}

// Strapi URI
export function getStrapiURI(path = "") {
	return `${
		process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
	}${path}`;
}
